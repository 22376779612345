import { FORM_NAME, isValidLicense } from '../utilities/licenses';
import { Icon, Modal, Segment } from 'semantic-ui-react';
import { IntroLayout, LicenseForm } from 'components';
import React, { useEffect, useState } from 'react';
import { getFormState, handleFormSubmit } from '../utilities/formstate';

import { FORM_NAME as INTRO_FORM_NAME } from '../utilities/introInformation';
import config from 'src/config';
import { createQueriesFromFormState as createQueries } from '../utilities/allQueries';
import { hasNoItems } from '../utilities/matchers';
import { navigate } from 'gatsby';
import { promiseTimeout } from '../utilities/misc';
import { sendLoginEmail } from '../utilities/auth';
import { signedUp } from '../events';

const LicensePage = () => {
  const formState = getFormState(FORM_NAME);
  const introState = getFormState(INTRO_FORM_NAME);
  const { state, fullName } = introState.values;
  const [licenses, setLicenses] = useState([]);
  const [isValidLicenseNumber, setIsValidLicenseNumber] = useState(true);
  const [isValidatingLicense, setIsValidatingLicense] = useState(false);
  const [submitAttempts, setSubmitAttempts] = useState(0);
  const [submitting, setSubmitting] = useState(false);

  const deleteLicense = license => {
    const newLicenses = licenses.filter(x => x !== license);
    setLicenses(newLicenses);
  };

  const addLicense = async license => {
    setIsValidLicenseNumber(true);

    if (licenses.includes(license)) return;

    setIsValidatingLicense(true);
    let isValid;

    try {
      isValid = await promiseTimeout(
        config.timeouts.licenseValidation,
        isValidLicense({ state, license })
      );
    } catch (err) {
      isValid = false;
    }

    setIsValidLicenseNumber(isValid);
    setIsValidatingLicense(false);

    if (isValid) setLicenses(licenses.concat(license));
  };

  useEffect(() => {
    if (!introState.values || Object.keys(introState.values).length === 0) {
      navigate('/');
      return <div></div>;
    }
  });

  const handleLicenseFormSubmit = async values => {
    setSubmitAttempts(submitAttempts + 1);

    if (hasNoItems(licenses)) return;

    setSubmitting(true);

    delete values.confirmPassword;

    const updated = handleFormSubmit(formState)(values, false);

    const signupDetails = createQueries(updated);

    await signedUp(signupDetails);
    await sendLoginEmail(introState.values.email);
    navigate('/confirmAccount');
  };

  const submitModal = () => (
    <Modal open={submitting}>
      <Modal.Header>Creating Your Account</Modal.Header>
      <Modal.Content>
        <Icon name="circle notched" loading />
        Hold tight for one minute......
      </Modal.Content>
    </Modal>
  );

  return (
    <IntroLayout text centered className="licenses">
      {submitModal()}
      <h1 className="hero-text">
        Let's get to know you, <br />
        {fullName}
      </h1>
      <Segment className="license-form">
        <LicenseForm
          submitAttempts={submitAttempts}
          licenses={licenses}
          addLicense={addLicense}
          deleteLicense={deleteLicense}
          isValidLicenseNumber={isValidLicenseNumber}
          setIsValidLicenseNumber={setIsValidLicenseNumber}
          isValidatingLicense={isValidatingLicense}
          state={state}
          initialValues={formState.values}
          working={formState.working || false}
          onSubmit={handleLicenseFormSubmit}
        />
      </Segment>
    </IntroLayout>
  );
};

export default LicensePage;
